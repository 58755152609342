<ng-container *transloco="let t">
  <div class="relative border-b-5 border-vermillon-500 pt-2xl md:pt-12.5">
    <div class="h-layout pb-2xl pt-navbar-thin md:pt-lg">
      <div class="relative">
        <div class="w-full text-center text-secondary">
          <div class="flex flex-col items-center">
            <h1
              [innerHTML]="t('homepage2.title')"
              class="text-shadow md: mt-xl pb-md font-bold leading-tight heading-2xl md:pb-xs md:heading-5xl"
            ></h1>
            <div class="flex items-center justify-center">
              <div
                class="flex w-1/2 min-w-[220px] flex-col flex-wrap items-center justify-center gap-x-md gap-y-sm pb-xl font-bold md:w-full md:flex-row md:gap-y-0"
              >
                <div class="flex flex-row gap-x-md">
                  <cft-benefit iconColor="bg-petrol-500">
                    <span class="text-shadow text-base">{{ t('homepage.benefits.1') }}</span>
                  </cft-benefit>
                  <cft-benefit iconColor="bg-gold-500">
                    <span class="text-shadow text-base">{{ t('homepage.benefits.2') }}</span>
                  </cft-benefit>
                </div>
                <div class="flex flex-row gap-x-md">
                  <cft-benefit iconColor="bg-purple-500">
                    <span class="text-shadow text-base">{{ t('homepage.benefits.3') }}</span>
                  </cft-benefit>
                  <cft-benefit iconColor="bg-rivergreen-500">
                    <span class="text-shadow text-base">{{ t('homepage.benefits.4') }}</span>
                  </cft-benefit>
                </div>
              </div>
            </div>
            <div class="flex w-full items-center justify-center">
              <div
                [routerLink]="searchLink"
                class="search-bar mb-xl block w-full max-w-[570px] cursor-pointer rounded-full bg-white py-xs pl-xl pr-xs md:max-w-[650px]"
              >
                <div class="flex items-center justify-between">
                  <div class="flex flex-col text-left">
                    <span class="hidden text-xs font-semibold text-president-500 md:inline-flex">{{
                      t('homepage.searchBar.title')
                    }}</span>
                    <span class="text-md text-president-400 md:text-xs">{{ t('homepage.searchBar.subtitle') }}</span>
                  </div>
                  <div>
                    <button
                      class="search-button-animation text-animation btn btn-vermilion flex h-[34px] w-[34px] justify-center rounded-full p-0 text-base md:h-[46px] md:w-[46px]"
                    >
                      <fa-icon [icon]="searchIcon" class="font-normal"></fa-icon>
                      <span class="button-text absolute opacity-0 transition-opacity">click me</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <!-- info table -->
            <div class="max-w-full rounded-lg border border-grey-400 shadow-6 md:w-[625px] md:shadow-none">
              <div class="m-auto items-center justify-center overflow-x-scroll rounded-lg no-scrollbar">
                <div class="flex justify-start rounded-lg">
                  <div class="tile-background flex w-full rounded-lg">
                    <div class="info-tile border-r">
                      <div class="info-tile-label">
                        {{ t('homepage.infoGrid.1.label') }}
                      </div>
                      <div class="info-tile-value">
                        {{ t('homepage.infoGrid.1.value') }}
                      </div>
                    </div>
                    <div class="info-tile border-r">
                      <div class="info-tile-label">
                        {{ t('homepage.infoGrid.2.label') }}
                      </div>
                      <div class="info-tile-value">
                        {{ t('homepage.infoGrid.2.value') }}
                      </div>
                    </div>
                    <div class="info-tile border-r">
                      <div class="info-tile-label">
                        {{ t('homepage.infoGrid.3.label') }}
                      </div>
                      <div class="info-tile-value">
                        {{ t('homepage.infoGrid.3.value') }}
                      </div>
                    </div>
                    <div class="info-tile shrink-1">
                      <div class="info-tile-label">
                        {{ t('homepage.infoGrid.4.label') }}
                      </div>
                      <div class="info-tile-value">{{ t('homepage.infoGrid.4.value') }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <bgo-hero-selling-point align="center"></bgo-hero-selling-point>

    <cft-wave-cover-image class="-z-1">
      <img
        alt=""
        class="banner-image"
        content="background-image"
        fill
        ngSrc="assets/images/home-cover-v6.jpg"
        priority
      />
    </cft-wave-cover-image>
  </div>
</ng-container>
