import {Inject, Injectable, PLATFORM_ID} from '@angular/core'
import {DOCUMENT, isPlatformBrowser} from '@angular/common'
import {noop} from 'rxjs'
import {Logger} from '../logging/logger.types'

@Injectable({providedIn: 'root'})
export class FaviconService {
  private lightSchemeIcon?: Element
  private darkSchemeIcon?: Element
  private window!: WindowProxy & typeof globalThis

  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    @Inject(DOCUMENT) private document: Document,
    private readonly logger: Logger,
  ) {
    if (isPlatformBrowser(this.platformId)) {
      if (!document.defaultView) throw Error('Window is not defined')

      this.window = document.defaultView
    }
  }

  public createFavicons(lightSchemePath: string, darkSchemePath: string): void {
    if (isPlatformBrowser(this.platformId)) {
      this.lightSchemeIcon = this.createFavicon(lightSchemePath)
      this.darkSchemeIcon = this.createFavicon(darkSchemePath)

      this.detectColorScheme()
    }
  }

  private createFavicon(path: string): Element {
    const element = this.document.createElement('link')
    element.setAttribute('rel', 'icon')
    element.setAttribute('href', path)
    element.setAttribute('type', 'image/x-icon')

    return element
  }

  private detectColorScheme() {
    try {
      if (this.mediaQueriesSupported()) {
        const matcher = this.window.matchMedia('(prefers-color-scheme: dark)')
        if (matcher?.addEventListener) {
          matcher.addEventListener('change', this.onColorSchemeUpdated.bind(this))
        } else if (matcher?.addListener) {
          matcher.addListener(this.onColorSchemeUpdated.bind(this))
        }
        this.onColorSchemeUpdated({matches: matcher?.matches})
      } else {
        this.onColorSchemeUpdated({matches: false})
      }
    } catch (err: unknown) {
      this.logger.debug(`Caught error when detecting color scheme: ${JSON.stringify(err)}`)
      this.lightSchemeIcon?.remove()
      this.darkSchemeIcon?.remove()
    }
  }

  private mediaQueriesSupported() {
    return 'matchMedia' in this.window && typeof this.window.matchMedia === 'function'
  }

  private onColorSchemeUpdated({matches}) {
    if (matches) {
      this.lightSchemeIcon?.remove()
      this.darkSchemeIcon ? this.document.head.append(this.darkSchemeIcon) : noop()
    } else {
      this.darkSchemeIcon?.remove()
      this.lightSchemeIcon ? this.document.head.append(this.lightSchemeIcon) : noop()
    }
  }
}
