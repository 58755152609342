import {Inject, Injectable, PLATFORM_ID, ViewContainerRef} from '@angular/core'
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationSkipped,
  NavigationStart,
  Router,
} from '@angular/router'
import {DOCUMENT, isPlatformBrowser} from '@angular/common'
import {LoadingProgressComponent} from './loading-progress.component'

@Injectable({
  providedIn: 'root',
})
export class LoadingProgressService {
  private progressComponent?: LoadingProgressComponent

  constructor(
    private readonly router: Router,
    @Inject(PLATFORM_ID) private readonly platformId: Record<string, unknown>,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {}

  private get isBrowser(): boolean {
    return isPlatformBrowser(this.platformId)
  }

  showOnRouteChange(viewContainerRef: ViewContainerRef) {
    if (!this.isBrowser) {
      return
    }

    this.createProgressComponent(viewContainerRef)

    this.router.events.subscribe(e => {
      switch (true) {
        case e instanceof NavigationStart:
          this.progressComponent?.start()
          break
        case e instanceof NavigationEnd:
          this.progressComponent?.complete()
          break
        case e instanceof NavigationCancel:
        case e instanceof NavigationError:
        case e instanceof NavigationSkipped:
          this.progressComponent?.reset()
          break
      }
    })
  }

  private createProgressComponent(viewContainerRef: ViewContainerRef) {
    const componentRef = viewContainerRef.createComponent(LoadingProgressComponent)
    this.document.body.prepend(componentRef.location.nativeElement)
    this.progressComponent = componentRef.instance
  }
}
