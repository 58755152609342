import {ChangeDetectionStrategy, Component} from '@angular/core'

@Component({
  selector: 'a[cft-nav-tab]',
  templateUrl: './nav-tab.component.html',
  styleUrls: ['./nav-tab.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class NavTabComponent {}
