import {ChangeDetectionStrategy, Component, Input} from '@angular/core'
import {faInfoCircle} from '@fortawesome/pro-solid-svg-icons/faInfoCircle'
import {faSearch} from '@fortawesome/pro-regular-svg-icons/faSearch'
import {Language} from '../../../../core/i18n/language'
import {ApplicationRoute} from '../../../../craft/utils/application.route'
import {PUBLIC_ROUTES} from '../../../../shared/routing/public.routes'
import {LinkService} from '../../../../core/i18n/link.service'

@Component({
  selector: 'bgo-home-banner',
  templateUrl: './home-banner.component.html',
  styleUrls: ['./home-banner.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeBannerComponent {
  @Input() partnerText!: string

  readonly searchLink = this.linkService.searchPath
  readonly infoIcon = faInfoCircle
  readonly searchIcon = faSearch
  readonly Language = Language
  readonly ArticlePage: ApplicationRoute = PUBLIC_ROUTES.ARTICLE_PAGE

  constructor(private readonly linkService: LinkService) {}
}
