import {DEFAULT_CURRENCY_CODE, LOCALE_ID, Provider} from '@angular/core'
import {TranslocoService} from '@ngneat/transloco'
import {Language} from './language'

export const provideLocaleId: () => Provider[] = () => [
  {
    provide: LOCALE_ID,
    useFactory: (translate: TranslocoService) => {
      const lang = translate.getActiveLang()
      switch (lang) {
        // use CH locale as it has major differences with German or French
        case Language.GERMAN:
        case Language.ENGLISH:
          return 'de-CH'
        case Language.FRENCH:
          return 'fr-CH'
        case Language.ITALIAN:
          return 'it-CH'
        default:
          return lang
      }
    },
    deps: [TranslocoService],
  },
  {
    provide: DEFAULT_CURRENCY_CODE,
    useValue: 'CHF',
  },
]
