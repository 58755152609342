import {HttpErrorResponse, HttpInterceptorFn, HttpResponse} from '@angular/common/http'
import {inject} from '@angular/core'
import {from, Observable, throwError} from 'rxjs'
import {catchError, map} from 'rxjs/operators'
import {hasGraphqlAuthenticationError, hasGraphqlForbiddenError} from '../graphql/graphql-utils'
import {LinkService} from '../i18n/link.service'
import {PUBLIC_ROUTES} from '../../shared/routing/public.routes'
import {REDIRECT_TO_PARAMETER, SIGN_OUT_PARAMETER} from '../../pages/public/auth/sign-in/sign-in.types'

export const AuthInterceptor: HttpInterceptorFn = (req, next) => {
  const linkService = inject(LinkService)
  return next(req).pipe(
    map(response => {
      if (hasGraphqlAuthenticationError(response) || hasGraphqlForbiddenError(response)) {
        throw response
      }

      return response
    }),
    catchError(err => handleAuthError(linkService, err)),
  )
}

/* eslint-disable @typescript-eslint/no-explicit-any */
function handleAuthError(
  linkService: LinkService,
  error: HttpErrorResponse | HttpResponse<any> | unknown,
): Observable<any> {
  // logout in case of authentication related error
  if (hasGraphqlAuthenticationError(error) || hasGraphqlForbiddenError(error)) {
    return logout(linkService)
  }

  // any other case, not handled here
  return throwError(() => error)
}

function logout(linkService: LinkService, redirectTo?: string | null): Observable<unknown> {
  const queryParams: Record<string, string> = {[SIGN_OUT_PARAMETER]: 'true'}
  let route = PUBLIC_ROUTES.SIGN_IN
  if (redirectTo) {
    route = PUBLIC_ROUTES.SIGN_IN_RETURNING
    queryParams[REDIRECT_TO_PARAMETER] = redirectTo
  }
  return from(linkService.navigateTo(route, {queryParams}))
}
