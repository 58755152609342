import {ChangeDetectionStrategy, Component} from '@angular/core'
import {LayoutService} from '../../../layout/layout.service'
import {LinkService} from '../../../../i18n/link.service'
import {faBars} from '@fortawesome/pro-solid-svg-icons/faBars'

@Component({
  selector: 'bgo-hamburger-menu',
  templateUrl: './hamburger-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HamburgerMenuComponent {
  readonly faBars = faBars

  constructor(
    private readonly layoutService: LayoutService,
    readonly linkService: LinkService,
  ) {}

  showSideMenu() {
    this.layoutService.setLayout({showSideMenu: true})
  }
}
