import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core'
import {LinkService} from './core/i18n/link.service'
import {FaviconService} from './core/favicon/favicon.service'
import {isPlatformBrowser} from '@angular/common'
import {QueryParamsService} from './core/query-params/query-params.service'
import {NotificationService} from './core/notifications/notification.service'
import {PUBLIC_ROUTES} from './shared/routing/public.routes'
import {searchTextQueryParam} from './pages/public/search/platform-search.types'
import {VersionService} from './core/version/version.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  constructor(
    @Inject(LinkService) public readonly linkService: LinkService,
    private readonly faviconService: FaviconService,
    private readonly queryParamsService: QueryParamsService,
    private readonly notificationService: NotificationService,
    @Inject(PLATFORM_ID) private readonly platformId: NonNullable<unknown>,
    private readonly versionService: VersionService,
  ) {
    this.faviconService.createFavicons('assets/favicon.ico', 'assets/favicon-neg.ico')
  }

  ngOnInit(): void {
    this.linkService.saveReferrer()

    if (isPlatformBrowser(this.platformId)) {
      const referrerUrl = document?.referrer ? new URL(document.referrer) : undefined
      const currentUrl = new URL(window.location.href)

      this.checkSSOCallback(currentUrl)
      this.checkSearchQueryParam(currentUrl)
      this.queryParamsService.applyQueryParams(currentUrl, referrerUrl)
      // Check every minute for a new platform version.
      this.versionService.checkForNewVersion(1000 * 60)
    }
  }

  checkSSOCallback(currentUrl: URL) {
    if (currentUrl.searchParams.get('signedIn') && currentUrl.searchParams.has('code')) {
      this.notificationService.signedIn()
    } else if (currentUrl.searchParams.get('signedIn') && currentUrl.searchParams.has('error')) {
      this.linkService.navigateTo(PUBLIC_ROUTES.AUTH_ERR)
    } else if (currentUrl.searchParams.get('signedOut')) {
      this.notificationService.signedOut()
    }
  }

  checkSearchQueryParam(currentUrl: URL) {
    const search = currentUrl.searchParams.get('search')
    if (search && search.length > 0) {
      this.linkService.navigateTo(PUBLIC_ROUTES.SEARCH, {queryParams: {[searchTextQueryParam]: search}})
    }
  }
}
