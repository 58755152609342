import {NgModule} from '@angular/core'
import {ToastComponent} from './toast.component'
import {SnackBarModule} from '../../../craft/snack-bar/snack-bar.module'
import {PipesModule} from '../../pipes/pipes.module'
import {ToastrModule} from 'ngx-toastr'

@NgModule({
  declarations: [ToastComponent],
  imports: [
    ToastrModule.forRoot({
      preventDuplicates: true,
      resetTimeoutOnDuplicate: true,
      // clear toastClass .ngx-toastr messes stuff up
      toastClass: '',
      toastComponent: ToastComponent,
      closeButton: true,
      easeTime: 150,
      timeOut: 5000,
      extendedTimeOut: 2000,
      progressBar: true,
      positionClass: 'toast-top-center',
    }),
    SnackBarModule,
    PipesModule,
  ],
  exports: [ToastComponent],
})
export class ToastModule {}
