import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core'
import {AnalyticsService} from './analytics.service'
import {environment} from '../../../environments/environment'

@Component({
  selector: 'bgo-analytics',
  standalone: true,
  imports: [],
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnalyticsComponent implements OnInit {
  constructor(private readonly analyticsService: AnalyticsService) {}

  // analytics a loaded on a global app component so that they can easily be deferred until app is idle
  ngOnInit() {
    if (environment.analytics.enabled) {
      this.analyticsService.setupAnalytics()
    }
  }
}
