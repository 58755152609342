import {Component, Directive, EventEmitter, Input, Output} from '@angular/core'
import {HomePageContent} from '../../../../core/content/types/home-page.types'
import {NgTemplateOutlet} from '@angular/common'
import {SupportSectionComponent} from '../support-section/support-section.component'
import {KnowledgeSectionComponent} from '../knowledge-section/knowledge-section.component'
import {PresentationSectionComponent} from '../../presentation-section/presentation-section.component'
import {RichTextModule} from '../../rich-text/rich-text.module'
import {HowItWorksSectionComponent} from '../how-it-works-section/how-it-works-section.component'
import {IndustrySectionComponent} from '../industry-section/industry-section.component'
import {InspirationSectionComponent} from '../inspiration-section/inspiration-section.component'
import {PartnersComponent} from '../../../../craft/partners/partners.component'
import {PromotionalBannerSectionComponent} from '../promotional-banner-section/promotional-banner-section.component'
import {ReviewsSectionComponent} from '../reviews-section/reviews-section.component'
import {ServicesSectionComponent} from '../services-section/services-section.component'
import {ContentSectionComponent} from '../../../../craft/content-section/content-section.component'

interface ContentTemplateContext {
  section: HomePageContent
  last: boolean
}

@Directive({
  selector: 'ng-template[content-template]',
  standalone: true,
})
export class ContentTemplateDirective {
  static ngTemplateContextGuard(dir: ContentTemplateDirective, ctx: unknown): ctx is ContentTemplateContext {
    return true
  }
}

@Component({
  selector: 'bgo-home-page-content',
  templateUrl: './home-page-content.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  standalone: true,
  imports: [
    NgTemplateOutlet,
    HowItWorksSectionComponent,
    ContentTemplateDirective,
    IndustrySectionComponent,
    InspirationSectionComponent,
    PartnersComponent,
    PromotionalBannerSectionComponent,
    ReviewsSectionComponent,
    ServicesSectionComponent,
    SupportSectionComponent,
    KnowledgeSectionComponent,
    PresentationSectionComponent,
    ContentSectionComponent,
    RichTextModule,
  ],
})
export class HomePageContentComponent {
  @Input() content!: HomePageContent[]

  @Output() howItWorksCtaClicked = new EventEmitter()
  @Output() reviewCtaClicked = new EventEmitter()
}
