import {Injectable} from '@angular/core'
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router'
import {Observable} from 'rxjs'
import {HomePage} from '../../../core/content/types/home-page.types'
import {ResolverUtilService} from '../../../core/resolver/resolver-util.service'
import {catchError} from 'rxjs/operators'
import {HomePageTypeKey, HomePageTypes} from './home-page.types'
import {HomePageContentService} from '../../../core/content/services/home-page-content.service'

@Injectable()
export class HomePageResolver {
  constructor(
    private readonly contentService: HomePageContentService,
    private readonly util: ResolverUtilService,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<HomePage> {
    const type: HomePageTypes = route.data[HomePageTypeKey] || HomePageTypes.Home
    return this.contentService.getHomePage(type).pipe(catchError(err => this.util.handleUnknownError(err)))
  }
}
