import {ChangeDetectionStrategy, Component} from '@angular/core'
import {faSearch} from '@fortawesome/pro-regular-svg-icons/faSearch'
import {faLightbulb} from '@fortawesome/pro-solid-svg-icons/faLightbulb'
import {faUserHelmetSafety} from '@fortawesome/pro-solid-svg-icons/faUserHelmetSafety'
import {faListUl} from '@fortawesome/pro-solid-svg-icons/faListUl'
import {LinkService} from '../../../i18n/link.service'
import {PUBLIC_ROUTES} from '../../../../shared/routing/public.routes'
import {map} from 'rxjs/operators'
import {AuthStatusService} from '../../../auth/auth-status.service'

@Component({
  selector: 'bgo-navbar-center',
  templateUrl: './navbar-center.component.html',
  styleUrls: ['./navbar-center.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarCenterComponent {
  protected readonly searchIcon = faSearch
  protected readonly articlesIcon = faLightbulb
  protected readonly servicesIcon = faListUl
  protected readonly articlesLink = this.linkService.localizedPathTo(PUBLIC_ROUTES.ARTICLE_OVERVIEW)
  protected readonly craftsmenLink = this.linkService.localizedPathTo(PUBLIC_ROUTES.CRAFTSMEN_REGION_PAGE_ROOT)
  protected readonly servicesLink = this.linkService.localizedPathTo(PUBLIC_ROUTES.PRO_DIRECTORY_PAGE)
  protected readonly craftsmenIcon = faUserHelmetSafety

  protected readonly user$ = this.authStatusService.authenticatedUser$.pipe(map(auth => auth.authenticatedUser))

  constructor(
    private readonly linkService: LinkService,
    readonly authStatusService: AuthStatusService,
  ) {}
}
