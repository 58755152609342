import {Observable} from 'rxjs'
import {HomePage} from '../types/home-page.types'
import {map} from 'rxjs/operators'
import {pickProperty} from '../../graphql/graphql-utils'
import {retryWithDelay} from '../../utils/retry-with-delay'
import {Injectable} from '@angular/core'
import {LinkService} from '../../i18n/link.service'
import {TranslocoService} from '@ngneat/transloco'
import {getSiteLocale} from '../site-locale'
import {GetHomePageGql} from '../../../../__generated__/datocms.types'
import {parseHomePage} from '../serde/home-page.serde'
import {CMS_GRAPHQL_CLIENT_NAME} from '../content-graphql-split'

@Injectable()
export class HomePageContentService {
  constructor(
    private readonly linkService: LinkService,
    private readonly locale: TranslocoService,
    private readonly getHomePageGql: GetHomePageGql,
  ) {}

  getHomePage(type: 'Home' | 'Mobiliar' = 'Home'): Observable<HomePage> {
    const locale = getSiteLocale(this.locale)
    return this.getHomePageGql.fetch({locale, type}, {context: {clientName: CMS_GRAPHQL_CLIENT_NAME}}).pipe(
      map(res => pickProperty(res.data, 'homePageModel')),
      map(page => parseHomePage(this.linkService, page)),
      // experimental: test retries with home page to see if that's improving reliability.
      // if satisfied, this type of retry mechanism should be moved as close as possible to the HTTP request (interceptor or link)
      retryWithDelay(1000, 3),
    )
  }
}
