import {HttpClient} from '@angular/common/http'
import {provideTransloco, Translation, TranslocoLoader, TranslocoModule} from '@ngneat/transloco'
import {Inject, Injectable, makeStateKey, NgModule, PLATFORM_ID, StateKey, TransferState} from '@angular/core'
import {environment} from '../../../environments/environment'
import {Language} from './language'
import {preventBgoProprietaryHttpInterceptorHeader} from '../http/prevent.interceptor'
import {LanguageService} from './language.service'
import {lastValueFrom, Observable} from 'rxjs'
import {isPlatformBrowser, isPlatformServer} from '@angular/common'

const I18N_STATE_KEY = (path: string): StateKey<Translation> => makeStateKey(`bgo.i18n.${path}`)

@Injectable({providedIn: 'root'})
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(
    private readonly http: HttpClient,
    private readonly transferState: TransferState,
    @Inject(PLATFORM_ID) private readonly platformId: Record<string, unknown>,
  ) {}

  /**
   * Loads the file containing the translations.
   * @param path - either the language like "en" or the language with some folder prefix e.g. "client/en".
   */
  async getTranslation(path: string): Promise<Translation> {
    if (isPlatformBrowser(this.platformId)) {
      // if we received state from the server, use it
      const translation = this.hydrateStateFromServer(path)
      if (translation) {
        return translation
      }
    }

    // fetch translations from remote
    const translation = await lastValueFrom(this.fetchTranslation(path))
    if (isPlatformServer(this.platformId)) {
      this.transferStateToBrowser(path, translation)
    }
    return translation
  }

  private fetchTranslation(path: string): Observable<Translation> {
    return this.http.get<Translation>(`${environment.i18n.baseUrl}/${path}.json`, {
      headers: {
        ...preventBgoProprietaryHttpInterceptorHeader,
      },
    })
  }

  private transferStateToBrowser(path: string, translation: Translation) {
    this.transferState.set(I18N_STATE_KEY(path), translation)
  }

  private hydrateStateFromServer(path: string): Translation | undefined {
    return this.transferState.get(I18N_STATE_KEY(path), undefined)
  }
}

@NgModule({
  exports: [TranslocoModule],
  providers: [
    provideTransloco({
      loader: TranslocoHttpLoader,
      config: {
        // use TRANSLOCO_CONFIG and a factory if a dependency is needed to configure transloco
        availableLangs: LanguageService.allSupportedLanguages(),
        defaultLang: Language.GERMAN,
        fallbackLang: Language.GERMAN,
        prodMode: environment.i18n.prodMode,
        reRenderOnLangChange: false,
      },
    }),
  ],
})
export class TranslocoRootModule {}
