<ng-container *transloco="let t">
  @if (
    {
      layout: layout$ | async,
      user: authStatus.authenticatedUser$ | async
    };
    as ctx
  ) {
    <div
      (click)="hideMenu()"
      class="invisible fixed bottom-0 left-0 right-0 top-0 z-50 bg-black/40 transition-opacity duration-[180ms]"
      [ngClass]="{'visible opacity-100': !!ctx.layout?.showSideMenu, 'invisible opacity-0': !ctx.layout?.showSideMenu}"
    ></div>
    <div
      class="fixed bottom-0 right-0 top-0 z-50 flex transform flex-col overflow-hidden rounded-l-3 bg-white shadow-3 transition-all delay-75 duration-300"
      [ngClass]="{
        'w-38 opacity-100 sm:w-48': !!ctx.layout?.showSideMenu,
        'w-0 px-0 opacity-0': !ctx.layout?.showSideMenu
      }"
    >
      <div class="fixed top-0 flex h-navbar-thin w-full items-center justify-between bg-white px-lg lg:h-navbar">
        <img ngSrc="assets/images/buildigo.svg" width="80" height="20" class="mt-1 h-[20px]" alt="" />
        <button class="btn btn-icon flex items-center space-x-sm bg-grey-100" (click)="hideMenu()">
          <fa-icon [icon]="closeIcon" class="text-lg"></fa-icon>
        </button>
      </div>
      <div class="mt-navbar-thin h-full overflow-y-scroll px-lg pb-[6.125rem] pt-md lg:mt-navbar">
        <div class="-mx-lg grid grid-cols-1">
          <!-- Searchbar -->
          @if ((ctx.user?.authenticatedUser | bgoPrioritizedUserRole) !== 'pro') {
            <cft-hero-searchbar class="mx-lg" (click)="openSearch()"></cft-hero-searchbar>
          }
          <!-- Content -->
          <div
            class="mt-xl"
            [ngClass]="{
              'order-3': ctx.user?.authenticatedUser
            }"
          >
            <span class="px-xl text-president-200 plot-md">
              {{ t('navbar.content') }}
            </span>
            <ul
              [ngClass]="{
                'border-b border-grey-300 pb-md': !ctx.user?.authenticatedUser
              }"
              cft-nav-list
              class="mx-md mt-sm"
            >
              <li>
                <a
                  cft-nav-list-item
                  id="nav-home"
                  [routerLink]="linkService.localizedPathTo(APPLICATION_ROUTES.HOME_LOCALIZED)"
                  [routerLinkActiveOptions]="{exact: true}"
                  routerLinkActive="active"
                >
                  {{ t('navbar.home') }}
                </a>
              </li>
              <li>
                <a
                  cft-nav-list-item
                  id="nav-craftsmen"
                  [routerLink]="linkService.localizedPathTo(APPLICATION_ROUTES.CRAFTSMEN_REGION_PAGE_ROOT)"
                  [routerLinkActiveOptions]="{exact: true}"
                  routerLinkActive="active"
                >
                  {{ t('navbar.craftsmen.title') }}
                </a>
              </li>
              <li>
                <a
                  cft-nav-list-item
                  id="nav-services"
                  [routerLink]="linkService.localizedPathTo(APPLICATION_ROUTES.PRO_DIRECTORY_PAGE)"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{exact: true}"
                >
                  {{ t('navbar.services') }}
                </a>
              </li>
              <li>
                <bgo-topic-overview-submenu></bgo-topic-overview-submenu>
              </li>
              <li>
                <a
                  cft-nav-list-item
                  id="nav-procucts"
                  [routerLink]="
                    linkService.localizedPathTo(APPLICATION_ROUTES.SERVICE_OVERVIEW_PAGE, {
                      routeParams: {pageSlug: 'overview'}
                    })
                  "
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{exact: true}"
                >
                  {{ t('navbar.products') }}
                </a>
              </li>
              <li>
                <a
                  cft-nav-list-item
                  routerLinkActive="active"
                  [routerLink]="
                    linkService.localizedPathTo(APPLICATION_ROUTES.CATEGORY_LANDING_PAGE, {
                      routeParams: {pageSlug: 'svit'}
                    })
                  "
                  >{{ t('navbar.buildingManagement') }}</a
                >
              </li>
            </ul>
          </div>
          <!-- Product recommendations -->
          @if (!ctx.user?.authenticatedUser || (ctx.user?.authenticatedUser | bgoPrioritizedUserRole) === 'client') {
            <div class="order-2 mt-xl space-y-md">
              <div class="px-xl text-president-200">
                <span class="plot-md">{{ t('navbar.productRecommendation') }}</span>
                <p class="mt-2xs text-xs">
                  {{ t('navbar.productRecommendationClientPlot') }}
                </p>
              </div>
              <ul cft-nav-list class="mx-md border-b border-grey-300 pb-md">
                @if (
                  !(ctx.user?.authenticatedUser | bgoPrioritizedUserRole) ||
                  (ctx.user?.authenticatedUser | bgoPrioritizedUserRole) === 'client'
                ) {
                  <li>
                    <a cft-nav-list-item [routerLink]="satisfactionGuaranteeLink" class="flex gap-md">
                      <fa-icon class="text-success" [icon]="satisfactionGuaranteeIcon"></fa-icon
                      >{{ t('navbar.satisfactionGuarantee') }}
                    </a>
                  </li>
                }
              </ul>
            </div>
          }
          <!-- Auth -->
          <ngx-loadable
            module="auth-submenu"
            [show]="!!ctx.layout?.showSideMenu"
            (init)="detectChanges()"
          ></ngx-loadable>
          <!-- Craftsman Sign Up -->
          @if (
            !ctx.user?.authenticatedUser?.pro &&
            !ctx.user?.authenticatedUser?.isGlobalAdministrator &&
            !ctx?.user?.authenticatedUser?.agent
          ) {
            <div class="order-4 mt-xl space-y-md">
              <div class="px-xl text-president-200">
                <span class="plot-md">{{ t('navbar.forCraftsmen') }}</span>
                <p class="mt-2xs text-xs">{{ t('navbar.forCraftsmenPlot') }}</p>
              </div>
              <div class="flex flex-col gap-sm px-lg">
                <a
                  class="btn btn-vermilion flex gap-xs"
                  id="nav-register-pro"
                  [routerLink]="linkService.localizedPathTo(APPLICATION_ROUTES.PRO_REGISTER)"
                >
                  <fa-icon [icon]="proSignupIcon"></fa-icon> {{ t('navbar.registerPro') }}
                </a>
                <a
                  class="btn btn-secondary-vermilion"
                  [routerLink]="linkService.localizedPathTo(APPLICATION_ROUTES.PRO_REGISTER)"
                >
                  {{ t('navbar.learnMore') }}
                </a>
              </div>
            </div>
          }
        </div>
      </div>
      <div
        class="fixed bottom-0 left-0 right-0 w-full border-t border-grey-200 bg-white/90 px-lg pb-md pt-xl text-center"
      >
        <bgo-language-picker-list></bgo-language-picker-list>
      </div>
    </div>
  }
</ng-container>
