<div class="space-y-3xl md:space-y-10">
  @for (section of content; track $index; let last = $last) {
    <ng-container *ngTemplateOutlet="contentTemplate; context: {section, last}"></ng-container>
  }

  <!--
  An IntelliJ bug causes template errors when using type narrowing with an @if inside of a @for loop
  https://youtrack.jetbrains.com/issue/WEB-65009/Angular-17-switch-and-if-syntax-does-not-handle-type-narrowing-by-object-property
  It will be fixed in 2024.1 - so we can get rid of this boilerplating then.
-->
  <ng-template content-template #contentTemplate let-section="section" let-last="last">
    @if (section.__type === 'HowItWorksSection') {
      <bgo-how-it-works-section
        [section]="section"
        (mainCallToActionClick)="howItWorksCtaClicked.emit()"
        class="h-layout"
        [class.pb-4xl]="last"
      ></bgo-how-it-works-section>
    }
    @if (section.__type === 'IndustrySection') {
      <bgo-industry-section [industrySection]="section" class="h-layout" [class.pb-4xl]="last"></bgo-industry-section>
    }
    @if (section.__type === 'InspirationSection') {
      <bgo-inspiration-section
        [inspirationSection]="section"
        class="h-layout"
        [class.pb-4xl]="last"
      ></bgo-inspiration-section>
    }
    @if (section.__type === 'PartnerSection') {
      <cft-partners [section]="section" class="h-layout" [class.pb-4xl]="last"></cft-partners>
    }
    @if (section.__type === 'PromotionalBannerSection') {
      <bgo-promotional-banner-section
        [section]="section"
        class="h-layout"
        [class.pb-4xl]="last"
      ></bgo-promotional-banner-section>
    }
    @if (section.__type === 'ReviewSection') {
      <bgo-reviews-section
        [reviewSection]="section"
        (buttonClicked)="reviewCtaClicked.emit()"
        class="h-layout"
        [class.pb-4xl]="last"
      ></bgo-reviews-section>
    }
    @if (section.__type === 'ServicesSection') {
      <bgo-services-section
        [servicesSection]="section"
        itemsElevation="flat"
        [itemsSeparator]="false"
        itemsCallToActionAppearance="hidden"
        class="h-layout"
        [class.pb-4xl]="last"
      ></bgo-services-section>
    }
    @if (section.__type === 'SupportSection') {
      <bgo-support-section [supportSection]="section"></bgo-support-section>
    }
    @if (section.__type === 'KnowledgeSection') {
      <bgo-knowledge-section [knowledgeSection]="section" class="h-layout"></bgo-knowledge-section>
    }
    @if (section.__type === 'PresentationSection') {
      <bgo-presentation-section [section]="section"></bgo-presentation-section>
    }
    @if (section.__type === 'ContentSection' && section.content.json) {
      <div class="h-layout">
        <cft-content-section [title]="section.title" [plot]="section.plot" titleAlignment="left" [class.pb-4xl]="last">
          <bgo-rich-text [content]="section.content"></bgo-rich-text>
        </cft-content-section>
      </div>
    }
  </ng-template>
</div>
