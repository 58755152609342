import {ChangeDetectionStrategy, Component} from '@angular/core'
import {ArticleContentService} from '../../../../../content/services/article-content.service'
import {PUBLIC_ROUTES} from '../../../../../../shared/routing/public.routes'
import {LinkService} from '../../../../../i18n/link.service'

@Component({
  selector: 'bgo-topic-overview-submenu',
  templateUrl: './topic-overview-submenu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ArticleContentService],
})
export class TopicOverviewSubmenuComponent {
  readonly topics$ = this.contentService.getTopicList()
  readonly overviewLink = this.linkService.localizedPathTo(PUBLIC_ROUTES.ARTICLE_OVERVIEW)

  constructor(
    private readonly contentService: ArticleContentService,
    private readonly linkService: LinkService,
  ) {}
}
