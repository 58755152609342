<ng-container *transloco="let t">
  <div class="flex h-5.5 overflow-hidden rounded-l-full">
    <a
      [routerLink]="linkService.homePath"
      aria-label="Home"
      class="flex items-center bg-grey-50 py-[0.625rem] pl-[1.5rem] pr-[0.875rem] hover:bg-grey-100"
    >
      <img [ngSrc]="'assets/images/buildigo.svg'" alt="" class="buildigo-logo" height="30" priority width="80" />
    </a>
  </div>
  <div class="group">
    <button
      [routerLink]="searchPath"
      aria-labelledby="search-label"
      class="flex h-[2.75rem] items-center gap-xs overflow-hidden rounded-r-full border-l border-grey-500 bg-grey-50 pl-[0.875rem] pr-[1rem] hover:bg-grey-100"
      type="button"
    >
      <fa-icon [icon]="searchIcon"></fa-icon>
      <span
        class="invisible box-border max-w-0 text-grey-700 transition-all sm:group-hover:visible sm:group-hover:max-w-[10rem] sm:group-hover:pr-[0.5rem]"
        id="search-label"
      >
        {{ t('navbar.search') }}
      </span>
    </button>
  </div>
</ng-container>
