import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from '@angular/core'

@Component({
  selector: 'bgo-navbar-right',
  templateUrl: './navbar-right.component.html',
  styles: [':host { @apply block; }'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarRightComponent {
  constructor(private readonly cdr: ChangeDetectorRef) {}

  detectChanges(): void {
    this.cdr.detectChanges()
  }
}
