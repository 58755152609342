import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core'
import {faBell} from '@fortawesome/pro-solid-svg-icons/faBell'
import {faClose} from '@fortawesome/pro-solid-svg-icons/faClose'
import {LocalStorageService} from '../../../core/storage/local-storage.service'
import {differenceInDays} from 'date-fns'
import {FaIconComponent} from '@fortawesome/angular-fontawesome'

@Component({
  selector: 'bgo-announcement-banner',
  templateUrl: './announcement-banner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FaIconComponent],
  standalone: true,
})
export class AnnouncementBannerComponent implements OnInit {
  @Input()
  readonly id!: string

  @Input()
  readonly text!: string

  @Input()
  readonly link!: string

  @Input()
  readonly linkText!: string

  hidden = false

  readonly closeIcon = faClose
  readonly bellIcon = faBell

  constructor(public readonly localStorageService: LocalStorageService) {}

  private get closedAtKey(): string {
    return `bgo.announcement-banner-${this.id}-closed-at`
  }

  ngOnInit(): void {
    const closedAtString = this.localStorageService.getItem(this.closedAtKey)
    if (closedAtString) {
      const closedAt = new Date(closedAtString)
      const now = new Date()
      const daysSince = differenceInDays(now, closedAt)
      if (daysSince > 7) {
        this.localStorageService.removeItem(this.closedAtKey)
      } else {
        this.hidden = true
      }
    }
  }

  close(): void {
    this.localStorageService.setItem(this.closedAtKey, new Date().toISOString())
    this.hidden = true
  }
}
