import {ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnDestroy} from '@angular/core'
import {faTimes} from '@fortawesome/pro-light-svg-icons/faTimes'
import {LinkService} from '../../../../i18n/link.service'
import {PUBLIC_ROUTES} from '../../../../../shared/routing/public.routes'
import {LayoutService} from '../../../layout/layout.service'
import {AuthStatusService} from '../../../../auth/auth-status.service'
import {filter, takeUntil} from 'rxjs/operators'
import {NavigationEnd, Router} from '@angular/router'
import {Subject} from 'rxjs'
import {faLock} from '@fortawesome/pro-solid-svg-icons/faLock'
import {faShieldCheck} from '@fortawesome/pro-solid-svg-icons/faShieldCheck'

@Component({
  selector: 'bgo-navbar-side-menu',
  templateUrl: './navbar-side-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarSideMenuComponent implements OnDestroy {
  readonly closeIcon = faTimes
  readonly proSignupIcon = faLock
  readonly satisfactionGuaranteeIcon = faShieldCheck

  readonly APPLICATION_ROUTES = PUBLIC_ROUTES
  readonly satisfactionGuaranteeLink = this.linkService.happinessGuaranteePagePath

  readonly layout$ = this.layoutService.layout$
  private readonly destroy$ = new Subject<void>()

  constructor(
    readonly linkService: LinkService,
    private readonly layoutService: LayoutService,
    private readonly cdr: ChangeDetectorRef,
    readonly authStatus: AuthStatusService,
    private readonly router: Router,
  ) {
    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        takeUntil(this.destroy$),
      )
      .subscribe(_ => {
        this.hideMenu()
      })
  }

  detectChanges(): void {
    this.cdr.detectChanges()
  }

  hideMenu() {
    this.layoutService.hideSideMenu()
  }

  openSearch() {
    this.hideMenu()
    this.linkService.navigateTo(PUBLIC_ROUTES.SEARCH)
  }

  @HostListener('window:keyup.esc', ['$event'])
  closeOnEsc() {
    this.hideMenu()
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }
}
