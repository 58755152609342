import {ChangeDetectionStrategy, Component} from '@angular/core'
import {LinkService} from '../../../i18n/link.service'
import {PUBLIC_ROUTES} from '../../../../shared/routing/public.routes'
import {faExternalLinkAlt} from '@fortawesome/pro-solid-svg-icons/faExternalLinkAlt'
import {faUsers} from '@fortawesome/pro-solid-svg-icons/faUsers'
import {NavbarService} from '../navbar.service'

@Component({
  selector: 'bgo-parent-brand-banner',
  templateUrl: './parent-brand-banner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        @apply block bg-white;
        @apply relative z-50;
      }
      :host-context(.layout-navbar--transparent:not(.layout-navbar--scrolled)) :host {
        @apply bg-president-500 text-white;
      }
    `,
  ],
})
export class ParentBrandBannerComponent {
  protected readonly aboutUsIcon = faUsers
  protected readonly aboutUsLink = this.linkService.localizedPathTo(PUBLIC_ROUTES.ABOUT_US)
  protected readonly externalLinkIcon = faExternalLinkAlt
  protected readonly mobiliarLink = this.linkService.localizedPathTo(PUBLIC_ROUTES.ARTICLE_PAGE, {
    routeParams: {pageSlug: 'partner-mobiliar'},
  })
  protected colour$ = this.navbarService.navbarColour$

  constructor(
    protected readonly navbarService: NavbarService,
    protected readonly linkService: LinkService,
  ) {}
}
