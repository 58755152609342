import {ChangeDetectionStrategy, Component, Input} from '@angular/core'
import {faCheck} from '@fortawesome/pro-solid-svg-icons/faCheck'
import {ShadedSystemBgColour} from '../utils/colour.types'
import {FaIconComponent} from '@fortawesome/angular-fontawesome'

function sanitizeValue(val: ShadedSystemBgColour) {
  return val ? val : ''
}

@Component({
  selector: 'cft-benefit',
  standalone: true,
  templateUrl: './benefit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FaIconComponent],
})
export class BenefitComponent {
  @Input({transform: sanitizeValue})
  iconColor?: ShadedSystemBgColour
  readonly checkIcon = faCheck
}
