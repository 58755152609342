import {Inject, Injectable} from '@angular/core'
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router'
import {Observable} from 'rxjs'
import {TranslocoService} from '@ngneat/transloco'
import {LanguageService} from './language.service'
import {Language} from './language'
import {ResolverUtilService} from '../resolver/resolver-util.service'

@Injectable({
  providedIn: 'root',
})
export class I18nGuard {
  constructor(
    @Inject(TranslocoService) private readonly localeService: TranslocoService,
    private readonly router: Router,
    private readonly languageService: LanguageService,
    private readonly utils: ResolverUtilService,
  ) {}

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const locale = this.findLocaleInRouteHierarchy(childRoute)

    // if locale code is not 2 characters, it's not a locale code but a not-found url
    if (locale && locale.length === 2) {
      const supportedLanguages = this.languageService.supportedLanguages()
      // locale param is the base path and could be a not found url
      // if it's not one of our available lang, treat it as not found
      if (supportedLanguages.includes(locale as Language)) {
        this.localeService.setActiveLang(locale)
        this.localeService.setAvailableLangs(supportedLanguages)
        this.languageService.setDocumentLanguage(locale)
        return true
      }

      return this.utils.redirectToUnsupportedLanguagePage(state.url)
    }

    return this.router.createUrlTree([this.localeService.getDefaultLang(), 'error', 'not-found'], {
      queryParams: {
        path: state.url,
      },
    })
  }

  private findLocaleInRouteHierarchy(childRoute: ActivatedRouteSnapshot) {
    let route: ActivatedRouteSnapshot | null = childRoute
    while (route) {
      const locale = route.paramMap.get('locale')
      if (locale) {
        return locale
      }
      route = route.parent
    }
    return undefined
  }
}
