import {NgModule} from '@angular/core'
import {NgOptimizedImage} from '@angular/common'
import {HomePageComponent} from './home-page.component'
import {TranslocoDirective} from '@ngneat/transloco'
import {HomeBannerComponent} from './banner/home-banner.component'
import {HappinessDialogComponent} from './banner/happinees-dialog/happiness-dialog.component'
import {WaveCoverImageModule} from '../../../craft/wave-cover-image/wave-cover-image.module'
import {FaIconComponent} from '@fortawesome/angular-fontawesome'
import {RouterLink} from '@angular/router'
import {BenefitComponent} from '../../../craft/benefit/benefit.component'
import {DialogModule} from '../../../craft/dialog/dialog.module'
import {HeroSellingPointComponent} from '../../../shared/components/hero-cover/hero-selling-point/hero-selling-point.component'
import {HomePageContentComponent} from '../../../shared/components/content/home-page-content/home-page-content.component'
import {HomePageResolver} from './home-page.resolver'
import {HomePageContentService} from '../../../core/content/services/home-page-content.service'

@NgModule({
  declarations: [HomePageComponent, HomeBannerComponent, HappinessDialogComponent],
  imports: [
    HomePageContentComponent,
    HeroSellingPointComponent,
    WaveCoverImageModule,
    NgOptimizedImage,
    TranslocoDirective,
    BenefitComponent,
    FaIconComponent,
    RouterLink,
    DialogModule,
  ],
  providers: [HomePageResolver, HomePageContentService],
})
export class HomePageModule {}
