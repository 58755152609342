import {HttpInterceptorFn} from '@angular/common/http'
import {inject} from '@angular/core'
import {TranslocoService} from '@ngneat/transloco'
import {shouldNotIntercept} from './prevent.interceptor'

export const I18nInterceptor: HttpInterceptorFn = (req, next) => {
  if (shouldNotIntercept(req)) {
    return next(req)
  }
  const localeService = inject(TranslocoService)
  const reqWithLang = req.clone({setHeaders: {'x-bgo-language': localeService.getActiveLang()}})
  return next(reqWithLang)
}
