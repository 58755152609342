import {platformBrowserDynamic} from '@angular/platform-browser-dynamic'
import {AppModule} from './app/app.module'
import {ConfigService} from './app/core/config/config.service'
import {APP_CONFIG_TOKEN} from '../server/config'

// first thing to do is load the config, as the app cannot live properly without it
// APP_INITIALIZER is not an option as it's not respected with lazy loaded modules
// https://stackoverflow.com/questions/49783765/angular-does-app-initializer-work-inside-of-lazy-loaded-modules
const loadConfig = ConfigService.loadConfig()

document.addEventListener('DOMContentLoaded', () => {
  window['bgo-ready'] = loadConfig.then(config => {
    return platformBrowserDynamic([{provide: APP_CONFIG_TOKEN, useValue: config}])
      .bootstrapModule(AppModule)
      .catch(err => console.error(err))
  })
})
