@if (
  {
    user: user$ | async
  };
  as ctx
) {
  <ng-container *transloco="let t">
    <ul class="menu-list">
      @if (!ctx.user?.pro) {
        <li>
          <a cft-nav-tab [routerLink]="craftsmenLink" routerLinkActive="active" class="menu-list__item">
            <fa-icon [icon]="craftsmenIcon"></fa-icon>
            <span class="hidden lg:inline">{{ t('navbar.craftsmen.title') }}</span>
            <cft-tooltip class="menu-list__item__tooltip">{{ t('navbar.craftsmen.title') }}</cft-tooltip>
          </a>
        </li>
        <li>
          <a class="menu-list__item" cft-nav-tab [routerLink]="servicesLink" routerLinkActive="active">
            <fa-icon [icon]="servicesIcon"></fa-icon>
            <span class="menu-list__item__title">{{ t('navbar.services') }}</span>
            <cft-tooltip class="menu-list__item__tooltip">{{ t('navbar.services') }}</cft-tooltip>
          </a>
        </li>
      }

      <li>
        <a class="menu-list__item" cft-nav-tab [routerLink]="articlesLink" routerLinkActive="active">
          <fa-icon [icon]="articlesIcon"></fa-icon>
          <span class="menu-list__item__title">{{ t('navbar.articles') }}</span>
          <cft-tooltip class="menu-list__item__tooltip">{{ t('navbar.articles') }}</cft-tooltip>
        </a>
      </li>
    </ul>
  </ng-container>
}
