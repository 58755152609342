import {MonoTypeOperatorFunction} from 'rxjs'
import {delay as delayOperator, map, retryWhen, scan} from 'rxjs/operators'

export function retryWithDelay<T>(delay: number, count = 1): MonoTypeOperatorFunction<T> {
  return retryWithDelayExceptWhen(delay, count)
}

export function retryWithDelayExceptWhen<T>(
  delay: number,
  count = 1,
  exceptWhen?: (err: unknown) => boolean,
): MonoTypeOperatorFunction<T> {
  return input =>
    input.pipe(
      retryWhen(errors =>
        errors.pipe(
          scan((acc, error) => ({count: acc.count + 1, error}), {
            count: 0,
            error: undefined,
          }),
          map(current => {
            const skipRetry = exceptWhen ? exceptWhen(current.error) : false
            if (skipRetry || current.count > count) {
              throw current.error
            }
          }),
          delayOperator(delay),
        ),
      ),
    )
}
