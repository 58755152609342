import {Logger, LogLevel, LogMeta} from './logger.types'

export class BrowserLogger implements Logger {
  private _isSilent = false

  debug(message: string, ...meta: LogMeta): void {
    this._log('debug', message, meta)
  }

  info(message: string, ...meta: LogMeta): void {
    this._log('info', message, meta)
  }

  error(message: string, ...meta: LogMeta): void {
    this._log('error', message, meta)
  }

  warn(message: string, ...meta: LogMeta): void {
    this._log('warn', message, meta)
  }

  log(message: string, ...meta: LogMeta): void {
    this._log('info', message, meta)
  }

  isSilent(): boolean {
    return this._isSilent
  }

  setSilent(silent: boolean): void {
    this._isSilent = silent
  }

  private _log(level: LogLevel, message: string, meta: LogMeta) {
    if (this._isSilent) {
      return
    }

    // add color to the level
    const levelAnnotated = `%c${level}`
    const levelColor = this.levelColor(level)

    console[level](levelAnnotated, levelColor, message, ...meta)
  }

  private levelColor(level: LogLevel): string {
    switch (level) {
      case 'info':
        return 'color:green'
      case 'warn':
        return 'color:orange'
      case 'error':
        return 'color:red'
      case 'debug':
        return 'color:rgb(103,160,229)'
      default:
        return ''
    }
  }
}
