import {ChangeDetectionStrategy, Component} from '@angular/core'
import {LinkService} from '../../../../i18n/link.service'
import {faSearch} from '@fortawesome/pro-solid-svg-icons/faSearch'

@Component({
  selector: 'bgo-navbar-logo',
  templateUrl: './navbar-logo.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: `
    :host {
      @apply relative flex min-w-[7.25rem];
    }

    .buildigo-logo {
      @apply cursor-pointer pt-2xs;
    }
  `,
})
export class NavbarLogoComponent {
  protected readonly searchIcon = faSearch
  protected readonly searchPath = this.linkService.searchPath

  constructor(protected readonly linkService: LinkService) {}
}
