import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {SnackBarComponent} from './snack-bar.component'
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome'

@NgModule({
  declarations: [SnackBarComponent],
  imports: [CommonModule, FontAwesomeModule],
  exports: [SnackBarComponent],
})
export class SnackBarModule {}
