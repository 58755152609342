import {HttpErrorResponse, HttpInterceptorFn, HttpResponse} from '@angular/common/http'
import {throwError} from 'rxjs'
import {shouldNotIntercept} from './prevent.interceptor'
import {catchError, map} from 'rxjs/operators'
import {
  getGraphqlErrorsByExtensionCode,
  hasGraphqlAuthenticationError,
  hasGraphqlErrorExtensionCode,
  hasGraphqlForbiddenError,
  throwGraphqlError,
} from '../graphql/graphql-utils'
import {retryWithDelayExceptWhen} from '../utils/retry-with-delay'
import {inject} from '@angular/core'
import {NotificationService} from '../notifications/notification.service'

export function ErrorInterceptorFactory(retryCount: number, retryDelay: number): HttpInterceptorFn {
  return (req, next) => {
    if (shouldNotIntercept(req)) {
      return next(req)
    }
    const notificationService = inject(NotificationService)
    return next(req).pipe(
      map(response => {
        if (hasGraphqlErrorExtensionCode(response, 'INTERNAL_SERVER_ERROR')) {
          throw response
        }

        return response
      }),
      retryWithDelayExceptWhen(retryDelay, retryCount, err => !isErrorHandled(err)),
      // handles all HTTP errors + errors thrown above in map
      catchError(err => handleError(notificationService, err)),
    )
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function handleError(
  notificationService: NotificationService,
  err: HttpErrorResponse | HttpResponse<unknown> | unknown,
) {
  if (isErrorHandled(err)) {
    const {message} = getGraphqlErrorsByExtensionCode(err, 'INTERNAL_SERVER_ERROR')
    notificationService.unknownError(message ?? err)

    if (message) {
      return throwGraphqlError(err)
    } else {
      return throwError(err)
    }
  }

  return throwError(err)
}

function isErrorHandled(err: HttpErrorResponse | unknown): boolean {
  // handle every error except for forbidden and authentication, which are handled by the auth interceptor
  return !(hasGraphqlForbiddenError(err) || hasGraphqlAuthenticationError(err))
}
