<ng-container *transloco="let t">
  <cft-nav-list-item-expandable [isExpanded]="false" [title]="t('navbar.articles')" [testIdList]="'articles-menu-list'">
    <ul cft-nav-list class="mt-1">
      <li>
        <a cft-nav-list-item [indentation]="1" id="nav-articles" [routerLink]="overviewLink" routerLinkActive="active">
          {{ t('navbar.overview') }}
        </a>
      </li>
      @for (topic of topics$ | async; track topic.slug) {
        <li>
          <a cft-nav-list-item [indentation]="1" [routerLink]="topic.path" routerLinkActive="active">
            {{ topic.breadcrumbTitle || topic.title }}
          </a>
        </li>
      }
    </ul>
  </cft-nav-list-item-expandable>
</ng-container>
