import {Provider} from '@angular/core'
import {Logger} from './logger.types'
import {BrowserLogger} from './logger.browser'

export const provideLogging: () => Provider[] = () => [
  {
    provide: Logger,
    useClass: BrowserLogger,
  },
]
