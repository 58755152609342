import {Component, NgZone, OnInit} from '@angular/core'
import {Toast, ToastPackage, ToastrService} from 'ngx-toastr'
import {SystemColour} from '../../../craft/utils/colour.types'
import {IconDefinition} from '@fortawesome/fontawesome-svg-core'
import {faBell} from '@fortawesome/pro-solid-svg-icons/faBell'
import {faCheckCircle} from '@fortawesome/pro-solid-svg-icons/faCheckCircle'
import {faInfoCircle} from '@fortawesome/pro-solid-svg-icons/faInfoCircle'
import {faWarning} from '@fortawesome/pro-solid-svg-icons/faWarning'
import {faXmarkCircle} from '@fortawesome/pro-solid-svg-icons/faXmarkCircle'

export type ToastType = 'success' | 'info' | 'warning' | 'error' | 'none'

/**
 * The toast component is just a wrapper around the craft {@link SnackBarComponent}
 */
@Component({
  selector: 'bgo-toast',
  templateUrl: './toast.component.html',
})
export class ToastComponent extends Toast implements OnInit {
  readonly type: ToastType

  constructor(toastrService: ToastrService, toastPackage: ToastPackage, ngZone?: NgZone) {
    super(toastrService, toastPackage, ngZone)
    this.type = mapToType(this.toastPackage.toastType)
  }

  ngOnInit(): void {
    if (!this.hasMessage) {
      throw new Error('Snackbar requires a message, toasts without a message are currently not supported.')
    }
  }

  get hasMessage(): boolean {
    if (this.message) {
      return this.message.length > 0
    }

    return false
  }

  get color(): SystemColour {
    switch (this.type) {
      case 'success':
        return 'green'
      case 'info':
        return 'blue'
      case 'warning':
        return 'orange'
      case 'error':
        return 'red'
      case 'none':
        return 'president'
    }
  }

  get icon(): IconDefinition {
    switch (this.type) {
      case 'success':
        return faCheckCircle
      case 'info':
        return faInfoCircle
      case 'warning':
        return faWarning
      case 'error':
        return faXmarkCircle
      case 'none':
        return faBell
    }
  }

  onClose(closed: boolean) {
    if (closed) {
      this.toastPackage.triggerTap()
    }
  }
}

function mapToType(classes: string | undefined): ToastType {
  switch (classes?.trim()) {
    case 'toast-success':
      return 'success'
    case 'toast-info':
      return 'info'
    case 'toast-warning':
      return 'warning'
    case 'toast-error':
      return 'error'
    default:
      return 'none'
  }
}
