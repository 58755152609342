<div class="bg-purple-500 text-white">
  <div class="h-layout flex items-center justify-center py-sm md:py-xs" [class.hidden]="hidden">
    <fa-icon class="mr-md md:mr-xl" [icon]="bellIcon"></fa-icon>
    <span>
      <span>{{ text }}</span>
      <a class="ml-xs underline" [href]="link">{{ linkText }}</a>
    </span>
    <fa-icon class="hover:text-grey ml-md md:ml-xl" [icon]="closeIcon" role="button" (click)="close()"></fa-icon>
  </div>
</div>
