import {Inject, Injectable, PLATFORM_ID} from '@angular/core'
import {BehaviorSubject, fromEvent, Observable} from 'rxjs'
import {isPlatformBrowser} from '@angular/common'
import {BreakpointService} from '../../../craft/utils/breakpoint.service'
import {LayoutService} from '../layout/layout.service'
import {distinctUntilChanged, map, mergeMap} from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class NavbarService {
  private readonly _navbarState$ = new BehaviorSubject<NavbarState>('top')

  readonly navbarState$ = this._navbarState$.asObservable()
  readonly navbarColour$: Observable<NavbarColour> = this.layoutService.layout$.pipe(
    map(layout => layout.transparentNavbar),
    mergeMap(transparent => this.navbarState$.pipe(map(state => ({transparent, state})))),
    mergeMap(({transparent, state}) =>
      this.breakpointService.breakpoints$.pipe(map(({md}) => ({transparent, state, md}))),
    ),
    map(({transparent, state, md}) => {
      if (state === 'scrolling') {
        return 'white'
      } else {
        return transparent ? 'transparent' : 'white'
      }
    }),
    distinctUntilChanged<NavbarColour>(),
  )

  constructor(
    private readonly breakpointService: BreakpointService,
    private readonly layoutService: LayoutService,
    @Inject(PLATFORM_ID) private readonly platformId: Record<string, unknown>,
  ) {
    this.observeScrolling()
  }

  observeScrolling() {
    if (isPlatformBrowser(this.platformId)) {
      fromEvent(window, 'scroll')
        .pipe(
          map(e => {
            const scrollY = (e.target as Document).defaultView?.scrollY
            return scrollY && scrollY > 40 ? 'scrolling' : 'top'
          }),
          distinctUntilChanged(),
        )
        .subscribe((val: NavbarState) => this._navbarState$.next(val))
    }
  }
}

export type NavbarState = 'top' | 'scrolling'
export type NavbarColour = 'white' | 'transparent'
