import {FieldPolicy, Reference} from '@apollo/client'

/**
 * Utility function for leveraging Apollo Core Pagination API with the buildigo API pagination pattern (page argument with offset and limit).
 *
 * This function will declare a field policy that can be used with any field that returns a paginated list of items.
 * The requirements are:
 *   - a field has a `page` parameter, with `offset` and `limit` arguments
 *   - the field returns an object with a `total` and `items` properties
 *
 * The keyArgs parameter is optional and can be used to specify the arguments that should be used to cache the results of this field.
 *
 * **References**:
 *  - https://www.apollographql.com/docs/react/pagination/core-api
 *  - https://the-guild.dev/graphql/apollo-angular/docs/data/pagination
 *  - Inspired by: https://github.com/apollographql/apollo-client/blob/main/src/utilities/policies/pagination.ts
 */
export function pageArgPagination<T = Reference>(
  keyArgs: FieldPolicy['keyArgs'] = false,
): FieldPolicy<{total: number; items: T[]}> {
  return {
    // Don't cache separate results based on
    // any of this field's arguments.
    keyArgs,
    // Concatenate the incoming list items with
    // the existing list items.
    merge(existing, incoming, {args}) {
      const merged = {total: incoming.total, items: existing ? existing.items.slice(0) : []}
      if (incoming) {
        if (args?.page) {
          // assume an offset of 0 if page.offset omitted.
          const {offset = 0} = args.page
          for (let i = 0; i < incoming.items.length; ++i) {
            merged.items[offset + i] = incoming.items[i]
          }
        } else {
          // It's unusual (probably a mistake) for a paginated field not
          // to receive any arguments, so you might prefer to throw an
          // exception here, instead of recovering by appending incoming
          // onto the existing array.
          merged.items.push(...incoming.items)
        }
      }

      return merged
    },
  }
}
