import {IMAGE_LOADER, ImageLoaderConfig} from '@angular/common'
import {Provider} from '@angular/core'
import {memoize} from '../../utils/memoize'
import {transformBuildigoImageUrl} from './buildigo-image-api'
import {transformDatocmsImageUrl} from './datocms'

export function imageApiLoader(config: ImageLoaderConfig): string {
  if (config.src.match(/datocms-assets.com/)) {
    return transformDatocmsImageUrl(config.src, {width: config.width})
  } else {
    // buildigo image API is the default
    return transformBuildigoImageUrl(config.src, {width: config.width})
  }
}

export const provideImageApiLoader: () => Provider[] = () => [
  {
    provide: IMAGE_LOADER,
    useValue: memoize(imageApiLoader), // use memoized function to avoid costly regex evals
  },
]
