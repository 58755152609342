import {ChangeDetectionStrategy, Component} from '@angular/core'
import {faSearch} from '@fortawesome/pro-solid-svg-icons/faSearch'
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome'
import {I18nDirective} from '../i18n/i18n.directive'

@Component({
  selector: 'cft-hero-searchbar',
  templateUrl: './hero-searchbar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  styleUrls: ['./hero-searchbar.component.css'],
  imports: [FontAwesomeModule, I18nDirective],
})
export class HeroSearchbarComponent {
  searchIcon = faSearch
}
