import {Inject, Injectable, OnDestroy, PLATFORM_ID} from '@angular/core'
import {ConfigService} from '../config/config.service'
import {interval, mergeMap, Subject} from 'rxjs'
import {map, take, takeUntil, tap} from 'rxjs/operators'
import {ToastrService} from 'ngx-toastr'
import {isPlatformBrowser} from '@angular/common'
import {TranslocoService} from '@ngneat/transloco'
import {AppConfig} from '../config/config.types'
import {Logger} from '../logging/logger.types'

@Injectable({providedIn: 'root'})
export class VersionService implements OnDestroy {
  private readonly destroy$ = new Subject<void>()

  constructor(
    private configService: ConfigService,
    private toastr: ToastrService,
    private transloco: TranslocoService,
    @Inject(PLATFORM_ID) private readonly platformId: NonNullable<unknown>,
    private readonly logger: Logger,
  ) {}

  checkForNewVersion(pollingIntervalInMs: number) {
    if (isPlatformBrowser(this.platformId)) {
      // To cleanup previous version change detect cycles
      const previousToastDestroy$ = new Subject<void>()
      interval(pollingIntervalInMs)
        .pipe(
          mergeMap(_ => ConfigService.fetchConfig<AppConfig>('/config.json')),
          map(config => config.version),
          tap(() => previousToastDestroy$.next()),
          takeUntil(this.destroy$),
        )
        .subscribe(newVersion => {
          const currentVersion = this.configService.config.version
          if (newVersion !== currentVersion) {
            this.logger.info(`New platform version is available (${newVersion}). Current Version is ${currentVersion}`)
            this.toastr
              .info(this.transloco.translate('platformUpdateToast.text'), undefined, {
                disableTimeOut: true,
                positionClass: 'toast-bottom-right',
                closeButton: false,
              })
              .onTap.pipe(take(1), takeUntil(previousToastDestroy$), takeUntil(this.destroy$))
              .subscribe(() => {
                location.reload()
              })
          }
        })
    } else {
      this.logger.info('Version check only works in the browser.')
    }
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }
}
