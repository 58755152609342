/* eslint-disable @typescript-eslint/no-explicit-any */
function memoize<T extends (...args: any[]) => any>(fn: T): T {
  // create a cache to store function results based on the input arguments.
  const memoizeCache = new Map<string, ReturnType<T>>()

  return function (...args: Parameters<T>): ReturnType<T> {
    // create a unique key based on the stringified version of the function arguments.
    const key = JSON.stringify(args)

    // check if the result is already cached based on the key.
    if (memoizeCache.has(key)) {
      return memoizeCache.get(key) as ReturnType<T>
    }

    // if the result is not cached, execute the original method and store the result in the cache.
    const result = fn.apply(this, args)
    memoizeCache.set(key, result)

    return result
  } as T
}

export {memoize}
